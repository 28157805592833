import axios from 'axios';

export const login = async (payload: any) => {
	const { data } = await axios({
		method: 'post',
		url: `/auth/login`,
		data: payload,
	});

	return data;
};

export const createUser = async (payload: any) => {
	const { data } = await axios({
		method: 'post',
		url: `/api/users`,
		data: payload,
	});

	return data;
};

export const getUserSelf = async () => {
	const { data } = await axios.get(`/api/users/self`);
	return data;
};

export const getUsers = async () => {
	const { data } = await axios.get(`/api/users`);
	return data;
};

export const updateUserSelf = async (payload: any) => {
	const { data } = await axios({
		method: 'put',
		url: `/api/users/self`,
		data: payload,
	});

	return data;
};

export const updateSettings = async (payload: any) => {
	const { data } = await axios({
		method: 'post',
		url: `/api/settings`,
		data: payload,
	});

	return data;
};

export const editUser = async (id: string, payload: any) => {
	const { data } = await axios({
		method: 'put',
		url: `/api/users/${id}`,
		data: payload,
	});

	return data;
};

export const getAssets = async (query: string) => {
	const { data } = await axios.get(`/api/assets/${query}`);
	return data;
};

export const searchAssets = async (query: string) => {
	const { data } = await axios.get(`/api/assets/search/${query}`);
	return data;
};

export const getAsset = async (id: string, query: string) => {
	const { data } = await axios.get(`/api/assets/${id}/${query}`);
	return data;
};

export const deleteAsset = async (assetId: string) => {
	const { data } = await axios.delete(`/api/assets/${assetId}`);
	return data;
};

export const updateAsset = async (id: string, payload: any) => {
	if (!id) {
		return null;
	}

	const { data } = await axios({
		method: 'put',
		url: `/api/assets/${id}`,
		data: payload,
	});

	return data;
};

export const createAsset = async (payload: any) => {
	const { data } = await axios({
		method: 'post',
		url: '/api/assets',
		data: payload,
	});

	return data;
};

export const bulkEditAssets = async (payload: any) => {
	const { data } = await axios({
		method: 'post',
		url: '/api/assets/bulk',
		data: payload,
	});

	return data;
};

export const rentOut = async (payload: any) => {
	const { data } = await axios({
		method: 'post',
		url: '/api/assets/rentOut',
		data: payload,
	});

	return data;
};

export const endRent = async (payload: any) => {
	const { data } = await axios({
		method: 'post',
		url: '/api/assets/endRent',
		data: payload,
	});

	return data;
};
// ------------- CYCLES ---------------

export const getCycles = async (assetId: string, query: string) => {
	const { data } = await axios.get(`/api/assets/${assetId}/cycles${query}`);
	return data;
};

export const getCycle = async (id: string) => {
	const { data } = await axios.get(`/api/cycles/${id}`);
	return data;
};

export const deleteCycle = async (id: string) => {
	const { data } = await axios.delete(`/api/cycles/${id}`);
	return data;
};

export const updateCycle = async (id: string, payload: any) => {
	if (!id) {
		return null;
	}

	const { data } = await axios({
		method: 'put',
		url: `/api/cycles/${id}`,
		data: payload,
	});

	return data;
};

export const createCycle = async (payload: any) => {
	const { data } = await axios({
		method: 'post',
		url: '/api/cycles',
		data: payload,
	});

	return data;
};

export const getReport = async (query: string) => {
	const { data } = await axios.get(`/api/reports/${query}`);
	return data;
};
